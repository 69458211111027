import { Injectable } from '@angular/core';

import { IAppConfig } from '../_models/app-config';
import { environment } from '../_environments/environment';

@Injectable({ providedIn: 'root' })
export class AppConfig {

    static settings: IAppConfig;
    static url: string;
    static ws: string;
    static pma: string;
    
    constructor() { }

    // Version 1
    load() {

        AppConfig.settings = environment;
        AppConfig.url = AppConfig.settings.webServiceProtocol + AppConfig.settings.webServiceDomain + ":" + AppConfig.settings.webServicePort + AppConfig.settings.webServiceContextPath;
        AppConfig.ws = AppConfig.settings.webSocketProtocol + AppConfig.settings.webServiceDomain + ":" + AppConfig.settings.webServicePort + AppConfig.settings.webServiceContextPath;
        AppConfig.pma = AppConfig.settings.phpMyAdminUrl;
        this.printAppConfigMode();
        
    }

    // Version 2
    // https://faun.pub/angular-on-docker-environment-specific-settings-1e92c3ad01e6
    // initializeApp(): Promise<any> {
    //     return new Promise(
    //         (resolve) => {
    //             this.http.get('assets/settings.json')
    //                 .toPromise()
    //                 .then(response => {
    //                         this.settingsService.settings = <Settings>response;
    //                         resolve();
    //                     }
    //                 )
    //         }
    //     );
    // }    

    // Version 3
    // this.configService.load()
    // .subscribe({
    //     next: (response: IAppConfig) => {
    //         AppConfig.settings = response;
    //         if (AppConfig.settings.debug) {
    //             console.log("next");
    //             console.log("Load env config: ", response);
    //         }
    //     },
    //     error: (e: any) => {
    //         console.log("Load env config error:", e);
    //     },
    //     complete() {
    //         if(AppConfig.settings.debug){
    //             console.log("Load env config completed.");	
    //         }
    //     },
    // });

    printAppConfigMode() {
        if (AppConfig.settings.debug) {
            console.log("AppConfig.settings.env: " + AppConfig.settings.env);
            console.log("AppConfig.settings: ", AppConfig.settings);
            console.log("AppConfig.url: ",  AppConfig.url);
            console.log("AppConfig.ws: ", AppConfig.ws);
        }

    }
}