import { IAppConfig } from "../_models/app-config";

export const environment: IAppConfig = {
    envType: "local",
    env: "local",
    production: false,
    debug: true,
    rootPath: "/Users/slavomar/Downloads/upload/",
    webSocketProtocol: "ws://",
    webServiceProtocol: "http://",
    webServiceDomain: "localhost",
    webServicePort: "1443",
    webServiceContextPath: "/api/v1",
    phpMyAdminUrl: "https://pma.nettingale.com/sso.php"
};
